/**
 * @file All of the scripts we still use for campaigns.
 */

window.ChorusCampaigns  = { clickUrls: {} };

/**
 * Records the click URL that this campaign will link to in the object.
 * @param  {String} trackerId The ID of the tracker to be kept by.
 * @param  {String} url       The URL that this will link to.
 */
window.ChorusCampaigns.recordClickUrl = function(trackerId, url) {
  this.clickUrls[trackerId] = url;
};

/**
 * Click on a campaign— what happens next? We go to the right URL.
 * @param  {String} trackerId The ID of the tracker to be kept by.
 */
window.ChorusCampaigns.click = function(trackerId) {
  if (this.clickUrls[trackerId]) {
    window.location = this.clickUrls[trackerId];
  }
};

/**
 * Creates a single tracking pixel for the campaigns to use.
 * @param  {String} url The URL to the tracking image.
 */
window.ChorusCampaigns.trackingImage = function(url) {
  // Create an image tag
  var img = document.createElement('img');
  // double %% in urls causes massive IE bugs. These can be added by special
  // URLs coming from dfp that include things like "%%CACHEBUSTER%%", which are
  // not always removed depending on where they come from.
  img.src = url.replace(/%%/g, '');

  // Height and width to 1.
  img.height = 1;
  img.width  = 1;

  // Make sure it doesn't mess with anything else in the stack.
  img.style = 'postion:absolute;';

  // Append to the body,
  document.body.appendChild(img);
};
