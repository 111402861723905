import './campaigns';
import './reskin';
import './concierge_plugins';
import Initializer from 'lib/initializer';
import _ from 'lib/utils';

window.concertAdsQueue.push(function(app) {
  // Added for backwards compatibility with all published ads.
  // TODO: Remove when ads are updated with new window reference.
  window.ChorusAds = app;

  Initializer.readyTask(installSlots);

  function installSlots() {
    const remoteConfigParam = _.deparamitize(location.search).concert_config_url;
    const remoteConfigUrl = (remoteConfigParam && remoteConfigParam.startsWith('https://concertads-configs.vox-cdn.com/')) ?
      remoteConfigParam : window.CONCERT_ADS_CONFIG.adConfigurationUrl;
    app.privacyCookiePreference = _.pick(window.Context.get('preferences'), 'privacy', 'cookies');

    if (remoteConfigUrl) {
      app.loadRemoteConfig(remoteConfigUrl).then(() => {
        app.install();
      });
    } else {
      app.install();
    }

    const legacySlots = window.CONCERT_ADS_CONFIG.recordedSlots;
    if (legacySlots && legacySlots.length) {
      legacySlots.forEach(slot => app.registerSlot(slot));
    }
  }
});

