const refreshPlugin = {
  install: function (API) {
    API.disableRefresh = {
      method: this.handleRefreshRequest,
      context: this,
    };

    return API;
  },

  handleRefreshRequest(frameObj, request, done) {
    let customEvent = request.refreshImmediately ? 'refreshImmediately' : 'refreshEligibilityToggle';

    const event = new CustomEvent(customEvent, {
      bubbles: true,
      detail: request
    });

    frameObj.el.dispatchEvent(event);
    done();
  }
};

const reverbPlugin = {
  install: function (API) {
    API.reverbPlayer = {
      method: this.handleReverbPlugin,
      context: this,
    };

    return API;
  },

  handleReverbPlugin(frameObj, requestData, done) {
    const frameEl = frameObj.el;
    const container = document.createElement('div');
    container.id = 'reverb-main';
    frameEl.parentNode.insertBefore(container, frameEl);

    const playerScript = document.createElement('script');
    playerScript.type = 'text/javascript';
    playerScript.src = `https://cdn.concert.io/reverb-video/${requestData}.js`;
    frameEl.parentNode.insertBefore(playerScript, frameEl);

    frameEl.style.display = 'none';

    done();
  }
};

const timeout = Date.now() + 20000; // wait 20 seconds

function initConciergePlugins(Concierge) {
  if (!Concierge || Date.now < timeout) {
    return setTimeout(initConciergePlugins, 100, window.ConcertConcierge);
  }

  const concierge = new window.ConcertConcierge();

  concierge.addPlugins([refreshPlugin, reverbPlugin]);
}

initConciergePlugins(window.ConcertConcierge);
