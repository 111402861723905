/**
 * @file This contains a series functions to support
 * Ad server delivered reskins.
 *
 * The primary responsibility is to listen to a message sent from DFP/GAM
 * iframe, this message includes styling and linking information that
 * is then setup on the document's body element.
 */

let reskinParams = {};

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

/**
 * Listens for message events, filters them and passes safe data
 * to the styling and click through functions for reskins
 * @param {*} e a message to validate
 */
function handleReskinRequest(e) {
  if (e.origin !== 'https://tpc.googlesyndication.com' &&
      e.origin !== 'http://tpc.googlesyndication.com') return;
  if (typeof e.data !== 'object') return;
  if (!e.data.reskinAd) return;
  if (!isValidHttpUrl(e.data.clickthroughUrl)) return; // anti-xss

  // looks safe enough, lets continue
  reskinParams = e.data;
  styleForReskin();
  attachBodyClickHandler();
  document.body.classList.add('has-reskin');
  // Tracking DOM mutation is difficult, periodically verify the reskin height
  // is correct set and styled.
  setInterval(styleForReskin, 5000);
}

window.addEventListener('message', handleReskinRequest, false);

/**
 * Attaches a click handler to the root element if a click through link is provided.
 */
function attachBodyClickHandler() {
  const rootElement = document.querySelector('.l-root.l-reskin');
  const wrapperElement = document.querySelector('.l-wrapper');


  if (reskinParams.clickthroughUrl) {
    document.body.addEventListener('click', (e) => {
      const wrapperWidth = wrapperElement ? wrapperElement.clientWidth : Infinity;
      const clickableWrapperWidth = (window.innerWidth - wrapperWidth) / 2;
      const isLeftGutterClick = e.pageX < clickableWrapperWidth;
      const isRightGutterClick = e.pageX > (window.innerWidth - clickableWrapperWidth);

      if (e.target == rootElement || isRightGutterClick || isLeftGutterClick) {
        window.open(reskinParams.clickthroughUrl, '_blank');
      }
    });
  }
}

/**
 * Returns the height for background image offset
 */
function getHeightOffset() {
  const siteHeader = document.querySelector('.l-header');
  let height = 0;

  if (siteHeader) {
    height += siteHeader.getBoundingClientRect().bottom + window.scrollY;
  }

  if (reskinParams.heightOffset) {
    height += reskinParams.offsetHeight;
  }

  return Math.max(0, height);
}

/**
 * Applies the styles to reskin the body element.
 */
function styleForReskin() {
  const heightOffset = getHeightOffset();
  if (reskinParams.leftBackgroundImage &&
    reskinParams.rightBackgroundImage &&
    reskinParams.centerBackgroundImage) {

    document.body.style.backgroundImage = `url(${reskinParams.leftBackgroundImage}), url(${reskinParams.rightBackgroundImage}), url(${reskinParams.centerBackgroundImage})`;
    document.body.style.backgroundRepeat = 'no-repeat, no-repeat, repeat-x';
    document.body.style.backgroundSize = 'auto, auto, auto';
    document.body.style.backgroundAttachment = 'fixed, fixed, fixed';
    document.body.style.backgroundPosition = `left ${heightOffset}px, right ${heightOffset}px, center ${heightOffset}px`;
  }

  if (reskinParams.backgroundColor) {
    document.body.style.backgroundColor = reskinParams.backgroundColor;
  }
}
